<template>
  <div class="page-table yetkiTanim mainDiv mb-30" id="affix-container">
    <div class="animated fadeInUp card-base baslikBg">Atama Tanımla</div>

    <el-row
      :gutter="10"
      v-loading="
        loading ||
        yoneticiListLoading ||
        firmaLoading ||
        projeLoading ||
        sozlesmeLoading
      "
      :element-loading-text="
        loading
          ? $t('src.views.apps.atama.tanimla.addLoading')
          : yoneticiListLoading
          ? $t('src.views.apps.atama.loading.yoneticiListLoading')
          : firmaLoading
          ? $t('src.views.apps.atama.loading.firmaLoading')
          : projeLoading
          ? $t('src.views.apps.atama.loading.projeLoading')
          : $t('src.views.apps.atama.loading.sozlesmeLoading')
      "
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(255, 255, 255, 1)"
    >
      <el-form
        status-icon
        :model="atamaForm"
        :rules="rulesYetki"
        ref="atamaForm"
        class="demo-ruleForm"
      >
        <el-col :lg="12" :md="12" :sm="24" :xs="24">
          <div
            class="card-base card-shadow--medium demo-box bg-white animated fadeInLeft pb-20 pt-10"
          >
            <el-row>
              <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                <el-form-item label="Personel" prop="yoneticiID"
                  >:
                  <el-select
                    style="width: 100%; padding-top: 12px"
                    size="small"
                    v-model="atamaForm.yoneticiID"
                    placeholder="Personel Seçiniz"
                    @change="yoneticiSecimi"
                    filterable
                    :filter-methos="filterMethod"
                  >
                    <el-option
                      v-for="item in yoneticiler"
                      :key="item.ID"
                      :label="item.adSoyad"
                      :value="item.ID"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
          <el-collapse
            class="animated fadeInUp"
            style="margin-top: 10px"
            v-model="activeCollapse"
          >
            <el-col :lg="24" :md="24" :sm="12" :xs="12">
              <el-collapse-item
                class="card-shadow--medium"
                :title="$t('src.views.apps.atama.tanimla.firmaAtamaTitle')"
                name="6"
                v-if="secim"
              >
                <div class="widget">
                  <el-select
                    v-if="isDesktop"
                    style="width: 97%; text-align: center; padding-left: 10px"
                    size="small"
                    v-model="atamaForm.musteriID"
                    @change="getProjeListesi"
                    placeholder="Firma Seçiniz"
                    filterable
                    :filter-methos="filterMethod"
                  >
                    <el-option
                      v-for="item in musteriler"
                      :key="item.musteriID"
                      :label="item.musteriAdi"
                      :value="item.musteriID"
                    >
                    </el-option>
                  </el-select>

                  <el-select
                    v-if="!isDesktop"
                    style="width: 70%; text-align: center; padding-left: 20px"
                    size="small"
                    v-model="atamaForm.musteriID"
                    @change="getProjeListesi"
                    placeholder="Firma Seçiniz"
                    filterable
                    :filter-methos="filterMethod"
                  >
                    <el-option
                      v-for="item in musteriler"
                      :key="item.musteriID"
                      :label="item.musteriAdi"
                      :value="item.musteriID"
                    >
                    </el-option>
                  </el-select>
                </div>
              </el-collapse-item>
            </el-col>

            <el-col :lg="24" :md="24" :sm="12" :xs="12">
              <el-collapse-item
                class="card-shadow--medium pt-10"
                :title="$t('src.views.apps.atama.tanimla.projeAtamaTitle')"
                name="7"
                v-if="musteriSecim"
              >
                <div class="widget">
                  <el-select
                    v-if="isDesktop"
                    style="width: 97%; text-align: center; padding-left: 10px"
                    size="small"
                    v-model="atamaForm.projeID"
                    @change="projeSelect"
                    placeholder="Proje Seçiniz"
                    filterable
                    :filter-methos="filterMethod"
                  >
                    <el-option
                      v-for="item in projeler"
                      :key="item.projeID"
                      :label="item.projeBaslik"
                      :value="item.projeID"
                    >
                    </el-option>
                  </el-select>
                  <el-select
                    v-if="!isDesktop"
                    style="width: 70%; text-align: center; padding-left: 20px"
                    size="small"
                    v-model="atamaForm.projeID"
                    @change="projeSelect"
                    placeholder="Proje Seçiniz"
                    filterable
                    :filter-methos="filterMethod"
                  >
                    <el-option
                      v-for="item in projeler"
                      :key="item.projeID"
                      :label="item.projeBaslik"
                      :value="item.projeID"
                    >
                    </el-option>
                  </el-select>
                </div>
              </el-collapse-item>
            </el-col>

            <el-col :lg="24" :md="24" :sm="12" :xs="12">
              <el-collapse-item
                class="card-shadow--medium pt-10"
                name="8"
                title="Görev Atama Yap"
                v-if="projeSecim"
              >
                <el-row>
                  <el-button
                    size="mini"
                    v-on:click="atamaYap('atamaForm', atamaForm.yoneticiID)"
                    class="onayBtn"
                    type="primary"
                  >
                    <label>Kaydet</label>
                  </el-button>
                  &nbsp;
                  <el-popover
                    placement="bottom"
                    width="320"
                    v-model="visibleSil"
                  >
                    <p>{{ $t("src.views.apps.messages.clearFormAlert") }}</p>
                    <div style="text-align: right; margin: 0">
                      <el-button
                        size="mini"
                        type="primary"
                        @click="resetForm('atamaForm')"
                      >
                        {{ $t("src.views.apps.genel.yes") }}
                      </el-button>
                      <el-button size="mini" @click="visibleSil = false">
                        {{ $t("src.views.apps.genel.no") }}
                      </el-button>
                    </div>
                    <el-button size="mini" slot="reference">
                      {{ $t("src.views.apps.genel.temizle") }}
                    </el-button>
                  </el-popover>
                </el-row>
              </el-collapse-item>
            </el-col>
          </el-collapse>
        </el-col>

        <el-col :lg="12" :md="12" :sm="24" :xs="24">
          <el-collapse
            class="animated fadeInUp"
            v-model="activeCollapse"
            v-if="projeSecim"
          >
            <el-collapse-item
              class="card-shadow--medium"
              :title="$t('src.views.apps.atama.tanimla.sozlesmeAtamaTitle')"
              name="9"
              v-if="gruplanmisSozlesmeler != ''"
            >
              <el-tree
                style="width: 100%; min-height: 400px; max-height: 400px"
                class="baskaTree scrollable scroll-x"
                node-key="id"
                :data="gruplanmisSozlesmeler"
                @check="handleSozlesmeNodeClick"
                icon-class
                :show-checkbox="true"
                ref="sozlesmeTree"
                :props="sozlesmeTreeProps"
                default-expand-all
                :check-strictly="true"
              >
                <!--
                <template v-slot:default="{ node, data }">
                    <div :style="{ backgroundColor: getColor(data)}">{{ node.label }}</div>
                </template>-->
              </el-tree>
            </el-collapse-item>
          </el-collapse>
        </el-col>
      </el-form>
    </el-row>
  </div>
</template>
<script>
import ClassicEditor from "../../../../node_modules/ckeditor5-build-classic";
import musteriService from "../../../WSProvider/MusteriService";
import notification from "../../../notification";
import yoneticiService from "../../../WSProvider/YoneticiService";
import projeService from "../../../WSProvider/ProjeService";
import sozlesmeService from "../../../WSProvider/SozlesmeService";
import EventBus from "@/components/event-bus";

var moment = require("moment");
let time = moment().format("YYYY-MM-DD");

export default {
  name: "AtamaTanimla",
  components: {},
  watch: {
    sozlesmeKey(val) {
      this.seciliSozlesmeFiltre();
      this.seciliSozlesmeler.length > 0
        ? (this.sozlesmeTarihBaslikGoster = true)
        : (this.sozlesmeTarihBaslikGoster = false);
    },
  },
  created() {
    this.checkDevice();
    EventBus.$on("projelistesi", () => {
      this.refreshPage();
    });
  },

  mounted() {
    window.addEventListener("resize", this.checkDevice);
    this.getMusteriListesi();
    // this.getProjeListesi();
    this.getYoneticiListesi();
    //this.sozlesmeKategoriAtamaList();
    //this.getYoneticilerinGorevleri();
    //this.getYetkiListesi();
    //this.getMusteriProjeBaslik();
    //this.getSozlesmeListesi();
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.checkDevice);
  },

  data() {
    return {
      secim: false,
      musteriSecim: false,
      projeSecim: false,
      sozlesmeSecim: false,
      isDesktop: false,
      loading: false,
      firmaLoading: false,
      projeLoading: false,
      sozlesmeLoading: false,
      yoneticiListLoading: false,
      visibleSil: false,
      disabled: false,

      musteriler: [],
      projeler: [],
      sozlesmeler: [],
      yoneticiler: [],
      yetkiler: [],
      gorevler: [],
      musteriProjeler: [],
      selectedFirmalar: [],
      selectedProjeler: [],
      selectedSozlesmeler: [],
      sozlesmelerNested: [],
      gruplanmisSozlesmeler: [],
      tempKategoriListesi: [],

      editor: ClassicEditor,
      editorConfig: {
        language: "tr",
      },
      activeCollapse: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11"],

      atamaForm: {
        musteriID: "",
        projeID: "",
        sozlesmeID: "",
        yoneticiID: "",
        atanacakFirmaProjeler: [],
        atanacakSozlesmeler: [],
        sozlesmeList: [],
      },
      selectIcon: "1",
      page: 0,
      postPage: 0,
      sizes: 10,

      rulesYetki: {
        yoneticiAdi: [
          {
            required: true,
            message: "Lütfen Yönetici Seçiniz",
            trigger: "blur",
          },
        ],
      },

      musteriTreeProps: {
        label: "musteriAdi",
        id: "musteriID",
      },

      projeTreeProps: {
        label: "projeBaslik",
        id: "projeID",
      },

      sozlesmeTreeProps: {
        label: "label",
        id: "id",
        children: "children",
      },
    };
  },
  methods: {
    getColor(data) {
      // console.log(data)
      if (data.sozlesmeBaslik == "BG Proje") {
        return "blue";
      }
      if (data.sozlesmeBaslik == "BG İnşaat") {
        return "#006cae";
      }
      if (data.sozlesmeBaslik == "BG Prefabrik") {
        return "#006cae";
      }
      if (data.sozlesmeBaslik == "BG Çelik") {
        return "#ff8b00";
      }
      if (data.sozlesmeBaslik == "BG Panel") {
        return "#181f38";
      }
      if (data.sozlesmeBaslik == "BG Lojistik") {
        return "#ce1e1e";
      }
      if (data.sozlesmeBaslik == "BG Enerji") {
        return "#0a8f3e";
      }
    },

    yoneticiSecimi(event) {
      this.secim = true;
      //var item = this.yoneticiler.find((el) => el.yoneticiID == event);
      this.atamaForm.musteriID = "";
      this.musteriSecim = false;
      this.projeSecim = false;
    },

    atama(yetkiKodu, secili) {
      if (secili == 1) {
        this.atamaForm.atanacakYetkiler.push(yetkiKodu);
      } else {
        const index = this.atamaForm.atanacakYetkiler.findIndex(
          (item) => item === yetkiKodu
        );
        if (index !== -1) {
          this.atamaForm.atanacakYetkiler.splice(index, 1);
        }
      }
    },

    getYoneticilerinGorevleri() {
      try {
        this.yetkiListLoading = true;
        yoneticiService
          .yoneticiGorevListele2()
          .then((response) => {
            localStorage.setItem("userDataBGSurec", response.token);
            if (response.status == 200) {
              this.gorevler = response.data;
              let sozlesmeKategoriAdi = null;
            }
            this.yetkiListLoading = false;
          })
          .catch((err) => {
            if (err.responseJSON) {
              let error = err.responseJSON;
              if (error.status == 401) {
                notification.Status(150, this);
              } else if (error.status == 404) {
                this.gorevler = [];
              } else notification.Status("errorMsg", this, error.errMsg);
            } else {
            }
            this.yetkiListLoading = false;
          });
      } catch (e) {
        this.yetkiListLoading = false;
      }
    },

    createVueTreeArray(array) {
      function traverse(item) {
        let newItem = {};
        if (item.sozlesmeBaslik) {
          newItem = {
            label: item.sozlesmeBaslik,
            id: item.sozlesmeID,
            kategoriAdi: item.sozlesmeKategoriAdi,
            kategoriID: item.sozlesmeKategoriID,
          };
        } else {
          newItem = {
            label: item.sozlesmeKategoriAdi,
          };
        }
        if (item.data) {
          newItem.children = item.data.map(traverse);
        }
        return newItem;
      }
      return array.map(traverse);
    },

    sozlesmeKategoriAtamaList() {
      try {
        this.yetkiListLoading = true;
        sozlesmeService
          .sozlesmeKategoriAtamaList(
            this.atamaForm.musteriID,
            this.atamaForm.projeID
          )
          .then((response) => {
            this.projeSecim = true;
            localStorage.setItem("userDataBGSurec", response.token);
            this.gorevler = response.data;
            console.log("görevler yeni", response.data);
            const arr = [];
            for (let key in response.data) {
              const obj = response.data[key];
              arr.push(obj);
            }
            console.log("original ağaç", arr);
            console.log("agaç", this.createVueTreeArray(arr));
            this.gruplanmisSozlesmeler = this.createVueTreeArray(arr);
            this.yetkiListLoading = false;
          })
          .catch((err) => {
            if (err.responseJSON) {
              let error = err.responseJSON;
              if (error.status == 401) {
                notification.Status(150, this);
              } else if (error.status == 404) {
                this.gorevler = [];
              } else notification.Status("errorMsg", this, error.errMsg);
            } else {
            }
            this.yetkiListLoading = false;
          });
      } catch (e) {
        this.yetkiListLoading = false;
      }
    },

    getMusteriListesi() {
      try {
        this.firmaLoading = true;
        musteriService
          .musteriListesi(this.selectIcon, "", "")
          .then((response) => {
            localStorage.setItem("userDataBGSurec", response.token);
            if (response.status == 200) {
              this.musteriler = response.data;
            }
            this.firmaLoading = false;
          })
          .catch((err) => {
            if (err.responseJSON) {
              let error = err.responseJSON;
              if (error.status == 401) {
                notification.Status(150, this);
              } else if (error.status == 404) {
                this.musteriler = [];
              } else notification.Status("errorMsg", this, error.errMsg);
            } else {
              //notification.Status(503, this)
            }
            this.firmaLoading = false;
          });
      } catch (e) {
        // notification.Status("success", this, "An error occurred while fetching the customer list.")
        this.firmaLoading = false;
      }
    },

    getProjeListesi() {
      this.projeLoading = true;
      try {
        musteriService
          .musteriProjeleri("", "", this.atamaForm.musteriID, 1)
          .then((response) => {
            localStorage.setItem("userDataBGSurec", response.token);
            if (response.count != 0) {
              this.projeler = response.data;
              this.musteriSecim = true;
              this.projeSecim = false;
              this.atamaForm.projeID = "";
            } else {
              notification.Status(
                "warn",
                this,
                "Gösterilecek proje bulunamadı"
              );
              this.musteriSecim = false;
              this.projeSecim = false;
            }
          })
          .catch((err) => {
            if (err.responseJSON) {
              let error = err.responseJSON;
              if (error.status == 401) {
                notification.Status(150, this);
              } else if (error.status == 404) {
                notification.Status("warning", this, error.msg);
              } else if (error.status == 503) {
                notification.Status(
                  "warning",
                  this,
                  "Bu müşterinin projesi bulunmamaktadır."
                );
              } else notification.Status("errorMsg", this, error.errMsg);

              this.musteriSecim = false;
              this.projeSecim = false;
              this.projeler = [];
            } else {
              //notification.Status(503, this)
            }
          })
          .finally(() => (this.projeLoading = false));
      } catch (e) {
        // notification.Status("success", this, "An error occurred while fetching the customer list.")
        this.projeLoading = false;
      }
    },

    getMusteriProjeBaslik() {
      try {
        this.firmaLoading = true;
        musteriService
          .musteriProjeBaslik()
          .then((response) => {
            localStorage.setItem("userDataBGSurec", response.token);
            if (response.status == 200) {
              this.musteriProjeler = response.data;
            }
            this.firmaLoading = false;
          })
          .catch((err) => {
            if (err.responseJSON) {
              let error = err.responseJSON;
              if (error.status == 401) {
                notification.Status(150, this);
              } else if (error.status == 404) {
                this.musteriProjeler = [];
              } else notification.Status("errorMsg", this, error.errMsg);
            } else {
              //notification.Status(503, this)
            }
            this.firmaLoading = false;
          });
      } catch (e) {
        // notification.Status("success", this, "An error occurred while fetching the customer list.")
        this.firmaLoading = false;
      }
    },

    getSozlesmeListesi() {
      try {
        this.sozlesmeLoading = true;
        projeService
          .projeSozlesmeleri(this.atamaForm.projeID)
          .then((response) => {
            console.log("sözleşmeler", response.data);
            localStorage.setItem("userDataBGSurec", response.token);
            if (response.count != 0) {
              this.sozlesmeler = response.data;

              // const gruplanmisSozlesmeler = [];
              // this.sozlesmeler.forEach((sozlesme) => {
              //   const sozlesmeKategoriAdi = sozlesme.sozlesmeKategoriAdi;
              //   const sozlesmeKategoriID = sozlesme.sozlesmeKategoriID;
              //   const grupIndex = gruplanmisSozlesmeler.findIndex(
              //     (grup) => grup.sozlesmeKategoriAdi === sozlesmeKategoriAdi
              //   );
              //   if (grupIndex !== -1) {
              //     gruplanmisSozlesmeler[grupIndex].sozlesmeler.push(sozlesme);
              //   } else {
              //     gruplanmisSozlesmeler.push({
              //       sozlesmeKategoriAdi: sozlesmeKategoriAdi,
              //       sozlesmeKategoriID: sozlesmeKategoriID,
              //       sozlesmeler: [sozlesme],
              //     });
              //   }
              // });
              // this.gruplanmisSozlesmeler = gruplanmisSozlesmeler;
            }
            this.sozlesmeLoading = false;
          })
          .catch((err) => {
            if (err.responseJSON) {
              let error = err.responseJSON;
              if (error.status == 401) {
                notification.Status(150, this);
              } else if (error.status == 404) {
                this.sozlesmeler = [];
              } else if (error.status == 503) {
                this.sozlesmeler = [];
                notification.Status(
                  "warning",
                  this,
                  "Bu projenin sözleşmesi bulunmamaktadır."
                );
              } else notification.Status("errorMsg", this, error.errMsg);
            } else {
              //notification.Status(503, this)
            }
            this.sozlesmeLoading = false;
          });
      } catch (e) {
        // notification.Status("success", this, "An error occurred while fetching the customer list.")
        this.sozlesmeLoading = false;
      }
    },

    projeSelect() {
      try {
        this.sozlesmeKategoriAtamaList();
      } catch (error) {
        console.log("error", error);
      }
    },

    getSozlesmeListesi2() {
      try {
        this.sozlesmeLoading = true;
        this.projeSecim = true;
        projeService
          .projeGrupluSozlesmeler(this.atamaForm.projeID)
          .then((response) => {
            localStorage.setItem("userDataBGSurec", response.token);
            if (response.count != 0) {
              console.log(" sözleşme verisi geldi", response);
              this.sozlesmeler = response.data;
            }
            this.sozlesmeLoading = false;
          })
          .catch((err) => {
            if (err.responseJSON) {
              let error = err.responseJSON;
              if (error.status == 401) {
                notification.Status(150, this);
              } else if (error.status == 404) {
                this.sozlesmeler = [];
              } else if (error.status == 503) {
                this.sozlesmeler = [];
                notification.Status(
                  "warning",
                  this,
                  "Bu projenin sözleşmesi bulunmamaktadır."
                );
              } else notification.Status("errorMsg", this, error.errMsg);
            } else {
              //notification.Status(503, this)
            }
            this.sozlesmeLoading = false;
          });
      } catch (e) {
        // notification.Status("success", this, "An error occurred while fetching the customer list.")
        this.sozlesmeLoading = false;
      }
    },

    /*
                getProjeListesi() {
                    try{
                        this.loading = true;
                        projeService.projeListesi(this.selectIcon,this.postPage, 100).then((response) => {
                            localStorage.setItem("userDataBGSurec", response.token)
                            if (response.status == 200) {
                                this.total = parseInt(response.data.projeSayisi);
                                this.projeler = response.data;
                                this.total = parseInt(response.count);
                            }
                            this.loading = false;
                        }).catch(err => {
                            if(err.responseJSON){
                                let error = err.responseJSON
                                if(error.status == 401){
                                    notification.Status(150, this);
                                }else if(error.status == 404){
                                    this.projeler = [];
                                }
                                else notification.Status("errorMsg", this, error.errMsg)
                            }else{
                                //notification.Status(503, this)
                            }
                            this.loading = false;
                        })
                    }catch(e){
                        // notification.Status("success", this, "An error occurred while fetching the customer list.")
                        this.loading = false
                    }
                },*/

    /*
    getSozlesmeListesi() {
        try{
            this.sozlesmeLoading = true;
            sozlesmeService.sozlesmeListesi(this.selectIcon,this.postPage, 50).then((response) => {
                localStorage.setItem("userDataBGSurec", response.token)
                if (response.status == 200) {
                    this.sozlesmeler = response.data;
                }
                this.sozlesmeLoading = false;
            }).catch(err => {
                if(err.responseJSON){
                    let error = err.responseJSON
                    if(error.status == 401){
                        notification.Status(150, this);
                    }else if(error.status == 404){
                        this.sozlesmeler = [];
                    }
                    else notification.Status("errorMsg", this, error.errMsg)
                }else{
                    //notification.Status(503, this)
                }
                this.sozlesmeLoading = false;
            })
        }catch(e){
            // notification.Status("success", this, "An error occurred while fetching the customer list.")
            this.sozlesmeLoading = false
        }
    },*/
    /*
                handleSozlesmeNodeClick(data, checked, indeterminate) {
                    this.selectedSozlesmeler = this.$refs.sozlesmeTree.getCheckedKeys();
                },*/

    handleSozlesmeNodeClick(checkedNodes, checkedKeys, halfCheckNodes) {
      this.atamaForm.sozlesmeList = checkedKeys.checkedNodes
        .filter((el) => el.id)
        .map((el) => {
          return {
            sozlesmeID: el.id,
            sozlesmeAdi: el.label,
            sozlesmeKategoriID: el.kategoriID,
            sozlesmeKategoriAdi: el.kategoriAdi,
          };
        });

      console.log("atama list ", this.atamaForm.sozlesmeList);
      this.tempKategoriListesi = [];
      checkedKeys.checkedNodes.forEach((el) =>
        this.tempKategoriListesi.push(el.id)
      );
      // this.selectedSozlesmeler = this.$refs.sozlesmeTree.getCheckedKeys();
    },

    atamaYap(formName) {
      this.$refs[formName].validate((valid, errors) => {
        if (valid) {
          this.$confirm(
            "Görev atamak istediğinize emin misiniz?",
            this.$t("src.views.apps.genel.uyari"),
            {
              confirmButtonText: this.$t("src.views.apps.genel.yes"),
              cancelButtonText: this.$t("src.views.apps.genel.no"),
              type: "warning",
            }
          )
            .then(() => {
              this.loading = true;
              if (this.atamaForm.yoneticiID == "") {
                notification.Status("warning", this, "Lütfen Personel Seçiniz");
                this.loading = false;
                return;
              }

              yoneticiService
                .gorevAtamaKategori(
                  this.atamaForm.yoneticiID,
                  this.atamaForm.musteriID,
                  this.atamaForm.projeID,
                  this.tempKategoriListesi
                )
                .then((response) => {
                  if (response.status == 200) {
                    localStorage.setItem("userDataBGSurec", response.token);
                    notification.Status(
                      "success",
                      this,
                      "Görev Ataması Başarı ile Tamamlandı"
                    );
                    this.resetForm(formName);
                    // EventBus.$emit("yetkiList", true)
                    //functions.sayfaKapat("yetkitanimla", this);
                  }

                  if (response.status == 202) {
                    notification.Status("warning", this, response.data);
                  }

                  this.loading = false;
                })
                .catch((err) => {
                  if (err.responseJSON) {
                    let error = err.responseJSON;
                    if (error.status == 401) {
                      notification.Status(150, this);
                    } else notification.Status("errorMsg", this, error.errMsg);
                  } else {
                    //notification.Status(503, this)
                    console.log("aa");
                  }
                  this.loading = false;
                });
            })
            .catch((e) => {});
        } else {
          for (var k in errors) {
            errors[k].forEach((element) => {
              notification.Status("warning", this, element.message);
            });
            this.loading = false;
          }
        }
      });
    },

    checkDevice() {
      this.isDesktop = window.innerWidth >= 821;
    },

    filterMethod(query, item) {
      return item.label.toLowerCase().indexOf(query.toLowerCase()) >= 0;
    },

    refreshPage() {
      this.page = 1;
      this.postPage = 0;
      this.loading = false;
      this.stateLoading = false;
      this.yoneticiListLoading = false;

      this.getMusteriListesi();
      this.getYoneticiListesi();
    },

    getYoneticiListesi() {
      try {
        this.yoneticiListLoading = true;
        yoneticiService
          .yoneticiListe(this.selectIcon, this.postPage, this.sizes, 2)
          .then((response) => {
            localStorage.setItem("userDataBGSurec", response.token);
            if (response.status == 200) {
              this.yoneticiler = response.data;
            }
            this.yoneticiListLoading = false;
          })
          .catch((err) => {
            if (err.responseJSON) {
              let error = err.responseJSON;
              if (error.status == 401) {
                notification.Status(150, this);
              } else if (error.status == 404) {
                this.yoneticiler = [];
              } else notification.Status("errorMsg", this, error.errMsg);
            } else {
            }
            this.yoneticiListLoading = false;
          });
      } catch (e) {
        this.yoneticiListLoading = false;
      }
    },

    indexMethod(index) {
      return (this.page - 1) * 10 + index + 1;
    },

    handleSizeChange(val) {
      this.sizes = val;
      this.getMusteriListesi();
      this.getYoneticiListesi();
    },

    handleChange(event) {
      this.selectIcon = event;
      this.page = 1;
      this.postPage = 0;
      this.getMusteriListesi();
      this.getYoneticiListesi();
      document
        .querySelector(".scrollable")
        .scrollTo({ top: 0, behavior: "smooth" });
    },

    pageChange(val) {
      this.postPage = (val - 1) * this.sizes;
      this.getMusteriListesi();
      this.getYoneticiListesi();
    },

    vazgec(formName) {
      this.$refs[formName].resetFields();
      this.editDialog = false;
      this.loading = false;
      this.stateLoading = false;
    },

    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.visibleSil = false;
      this.yoneticiSecimi();
      this.secim = false;
    },
  },
};
</script>

<style lang="scss">
.sozlesmeSelect {
  .el-row {
    padding: 10px 10px 10px 8px;
  }

  .el-row:nth-child(2n) {
    background: #f3f7fa !important;
  }
}
</style>

<style></style>
